.loading {
    border-radius: 50%;
    border: 16px solid #bdc3c7;
    border-top-color: #5bc0de;
    border-bottom-color: #5bc0de;
    background: transparent;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    animation: spinny 2s alternate-reverse infinite forwards;
}

@keyframes spinny {
    0% {
        transform: rotate(0deg) scale(1);

    }

    50% {
        transform: rotate(180deg) scale(1.05)
    }


    100% {
        transform: rotate(360deg) scale(1);
        border-top-color: #5cb85c;
        border-bottom-color: #5cb85c;

    }
}
