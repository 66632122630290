.selectedpracTypeButton, .selectedlanguagesButton, .selectedprimarySpecialismsButton, .selectedsecondarySpecialismsButton {
  color: red;
}

.practionersView {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.practionerPreview {
  max-width: 33vw;
}

.practioner-preview-box {
  max-width: 33vw;
}
